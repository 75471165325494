import * as R from 'ramda'
import {graphql} from 'gatsby'
import {objectOf, shape} from 'prop-types'
import loadable from '@loadable/component'
import React from 'react'

import {managementPropTypes} from 'helpers/propTypes'
import Hero from 'components/UI/Hero'
import ManagementList from 'components/AboutUs/Management'
import Section from 'components/UI/Section'
import SEO from 'components/seo'

const Management = ({pageContext, data}) => {
  const countryData = R.path(['contentfulCountry'], data)
  const Decoration = loadable(() => import('components/UI/Decoration'))
  const Decorations = loadable(() => import('components/UI/Decorations'))

  const {imageOrVideo, metaTitle, pageTitle, surtitle} = R.pathOr(
    null,
    ['contentfulManagement'],
    data,
  )

  const metaDescription = R.pathOr(
    ' ',
    ['contentfulManagement', 'metaDescription', 'metaDescription'],
    data,
  )

  const shortDescription = R.pathOr(
    ' ',
    ['contentfulManagement', 'shortDescription', 'shortDescription'],
    data,
  )

  const managementTeams = R.pathOr(
    null,
    ['contentfulCountry', 'managementTeams'],
    data,
  )

  const instaIcon = R.pathOr(
    null,
    ['contentfulSocialMediaShare', 'icon', 'file', 'url'],
    data,
  )

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription:
      R.pathOr('', ['metaDescription'], metaDescription) || metaDescription,
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <div className="decorationWrapper">
        <Decorations>
          <Decoration webSiteName="keyrus" color="blue" top={52} />
          <Decoration webSiteName="keyrus" color="orange" right={0} top={60} />
          <Decoration
            webSiteName="keyrus"
            color="red"
            right={0}
            bottom={-160}
          />
          <Decoration webSiteName="kls" svg="left" left={-80} top={0} />
          <Decoration webSiteName="kls" svg="right" right={0} top={34} />
        </Decorations>
        <Section hasPaddingTop={false} hasPaddingBottom={false}>
          {pageTitle && (
            <Hero
              hat={surtitle}
              title={pageTitle}
              description={shortDescription}
              media={imageOrVideo}
              titleType="title"
            />
          )}
        </Section>
      </div>
      {managementTeams && (
        <ManagementList
          managementTeams={managementTeams}
          instaIcon={instaIcon}
        />
      )}
    </>
  )
}

Management.propTypes = managementPropTypes

Management.defaultProps = shape({
  data: objectOf(
    shape({
      imageOrVideo: null,
      metaTitle: '',
      metaDescription: null,
      surtitle: '',
      pageTitle: '',
      shortDescription: null,
      managementTeams: null,
      instaIcon: null,
    }),
  ),
})

export default Management

export const pageQuery = graphql`
  query templateManagement(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    contentfulManagement(
      node_locale: {eq: $nodeLocale}
      slug: {regex: $entityRegEx}
    ) {
      callToAction
      imageOrVideo {
        file {
          url
          contentType
        }
        gatsbyImageData(placeholder: BLURRED)
        title
        description
      }
      metaTitle
      metaDescription {
        metaDescription
      }
      pageTitle
      surtitle
      shortDescription {
        shortDescription
      }
    }
    contentfulSocialMediaShare(name: {regex: "/LinkedIn/"}) {
      icon {
        file {
          url
        }
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    contentfulCountry(
      node_locale: {eq: $nodeLocale}
      technicalName: {eq: $technicalName}
    ) {
      ...countryDataFields
      managementTeams {
        id
        name
        pageTitle
        managers {
          id
          jobTitle
          linkedInProfile
          name
          photo {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`
