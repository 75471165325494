import * as R from 'ramda'
import {managementListPropTypes} from 'helpers/propTypes'
import {shape} from 'prop-types'
import React from 'react'

import Description from 'components/UI/Description'
import Media from 'components/UI/Media'
import RawText from 'components/UI/RawText'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'
import useIsMobile from 'hooks/useIsMobile'

import useStyles from './styles'

const ManagementList = ({instaIcon, managementTeams}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()

  const mapIndexed = R.addIndex(R.map)

  return mapIndexed((managementTeam, index) => {
    const {pageTitle, managers} = managementTeam

    return (
      managers && (
        <Section
          key={index}
          hasGreyBackground={
            R.length(managementTeams) <= 2 ? true : index % 2 === 0
          }
          hasSmallPadding
          hasPaddingBottom={false}
          hasBorderBottom={
            R.length(managementTeams) <= 2
              ? false
              : index === managementTeams.length - 1
          }
        >
          <div className={classes.heading}>
            <Title
              variant="h2"
              type="subTitle"
              isCentered={isMobile}
              fontFamily="book"
            >
              {pageTitle}
            </Title>
          </div>
          <div className={classes.wrapper}>
            {managers &&
              R.map(manager => {
                const {
                  id,
                  jobTitle,
                  linkedInProfile,
                  name,
                  photo,
                  biography,
                  showBiography,
                } = manager

                return (
                  <article className={classes.card} key={id}>
                    <div className={classes.image}>
                      {photo && (
                        <>
                          <Media
                            data={photo.gatsbyImageData}
                            alt={photo.description}
                          />
                        </>
                      )}
                    </div>
                    <div className={classes.titleWrapper}>
                      <div className={classes.title}>
                        <Title variant="h3" type="subTitle" fontFamily="book">
                          {name}
                        </Title>
                      </div>
                    </div>
                    <Description>{jobTitle}</Description>
                    {showBiography && <RawText text={biography} />}
                    <a
                      href={linkedInProfile}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className={classes.icon}
                        alt="instagram icon"
                        src={instaIcon}
                        loading="lazy"
                      />
                    </a>
                  </article>
                )
              }, managers)}
          </div>
        </Section>
      )
    )
  }, managementTeams)
}

ManagementList.propTypes = managementListPropTypes
ManagementList.defaultProps = shape({
  managementTeams: null,
  instaIcon: null,
})

export default ManagementList
