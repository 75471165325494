import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, spacing, layout, breakpoints}) => ({
  wrapper: {
    color: palette.background.lightDark,
    margin: spacing(9, 0),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  titleWrapper: {
    display: 'inline-block',
    overflowWrap: 'break-word',
    height: 80,
    justifyContent: 'center',
    margin: spacing(1, 0),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    height: 'inherit',
  },
  card: {
    width: layout.aboutUs.managementCardWidth,
    padding: spacing(0, 1),
    display: 'flex',
    marginBottom: spacing(5),
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: spacing(3),
    textAlign: 'center',
    '& h3': {
      padding: 0,
    },
    '& p': {
      [breakpoints.up('sm')]: {
        minHeight: 48,
      },
    },

    [breakpoints.up('md')]: {
      flex: 1,
      minWidth: '33%',
    },
  },
  image: {
    width: layout.aboutUs.managementImage,
    height: layout.aboutUs.managementImage,
    background: palette.background.default,
    position: 'relative',
    borderRadius: spacing(10),
    transform: 'translateZ(0)',

    '& div': {
      borderRadius: spacing(10),
      transform: 'translateZ(0)',

      width: '100%',
      height: '100%',
    },
  },
  icon: {
    marginTop: spacing(1),
    width: 24,
    height: 24,
  },
}))

export default useStyles
